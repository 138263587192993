<template>
  <div class="my-6 md:my-0 flex flex-col md:flex-row items-start">
    <SectionLeft />
    <div class="h-full md:h-screen w-full md:w-6/12 px-4 md:pr-16 lg:pr-32">
        <ManageteamSection />
    </div>
    <DataserviceLogo />
  </div>
</template>

<script>
import SectionLeft from "../components/SectionLeft.vue";
import ManageteamSection from "../components/manageteam/ManageteamSection.vue";
import DataserviceLogo from "../components/DataserviceLogo.vue"

export default {
  components: {
    SectionLeft,
    ManageteamSection,
    DataserviceLogo
  },
};
</script>