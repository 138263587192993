<template>
  <div class="flex flex-col justify-center items-center font-proximaMedium">
    <HeaderGlobal
      :titlemenu1="$t('account_dropdown_menu_settings')"
      titlemenu2="Account Info"
      :to2="'/account'"
      titlemenu3="Manage Team"
    />

    <div class="w-11/12 mt-20 text-left">
      <div>
        <div class="">
          <TagInput v-on:getInputs="getEmailInputs"></TagInput>
        </div>
        <SubmitButton
          :title="$t('manage_team_section_button')"
          background="bg-primary"
          width="w-max"
          class="mx-auto"
          @click.native="showModal = true"
        />
      </div>
      <div class="mt-14 text-mediumGrey">
        <h2>Existing Team Members</h2>
        <ul class="mt-6">
          <li class="flex items-center justify-between">
            <div class="flex items-center">
              <img
                src="https://www.its.ac.id/international/wp-content/uploads/sites/66/2020/02/blank-profile-picture-973460_1280-1-300x300.jpg"
                alt="member picture"
                class="mr-4 h-10 rounded-full"
              />
              <span>Santa Cruz</span>
            </div>
            <div
              class="flex justify-between items-center md:flex-col lg:flex-row"
            >
              <SmallButton
                :title="$t('global_delete')"
                borderColor="border-orange"
                textCl="text-orange"
                width="w-max"
                paddingX="px-5"
              />
              <SmallButton
                :title="$t('global_enable')"
                borderColor="border-primary"
                textCl="text-primary"
                width="w-max"
                paddingX="px-5"
                class="ml-2 md:ml-0 md:mt-2 lg:ml-2 lg:mt-0"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>

    <ModalsAddmember :show="showModal" @close="showModal = false">
      <template #header>
        <h3>custom header</h3>
      </template>
    </ModalsAddmember>
  </div>
</template>

<script>
import SubmitButton from "../buttons/SubmitButton.vue";
import SmallButton from "../buttons/SmallButton.vue";
import HeaderGlobal from "../header/HeaderGlobal.vue";
import ModalsAddmember from "../modals/ModalsAddmember.vue";
import TagInput from "../forms/TagInput.vue";

export default {
  components: {
    HeaderGlobal,
    SubmitButton,
    SmallButton,
    ModalsAddmember,
    TagInput,
  },
  data() {
    return {
      inputs: {
        emails: [],
        invalid: false,
      },
      showModal: false,
    };
  },
  methods: {
    getEmailInputs(inputs) {
      this.inputs = inputs;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>