<template>
  <div
    class="
      py-1
      text-white text-center text-sm
      font-proximaNormal
      rounded-md
      shadow-sm
      border
      cursor-pointer
    "
    :class="[width, textCl, borderColor, paddingX, backgroundColor]"
  >
    {{ title }}
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: [String],
      default: "w-full",
    },
    paddingX: {
      type: [String],
      default: "px-2",
    },
    title: {
      type: [String],
      default: "this is title",
    },
    textCl: {
      type: [String],
      default: "text-mediumGrey",
    },
    borderColor: {
      type: [String],
      default: "border-mediumGrey",
    },
    backgroundColor: {
      type: [String],
      default: "bg-white",
    }
  }
};
</script>