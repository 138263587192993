<template>
  <div class="h-full md:h-screen w-full md:w-6/12 px-4 md:pl-16 lg:pl-32">
    <!-- class="h-32 pt-8 hidden md:flex" -->
    <div v-if="!publicScreen" @click="routeTo">
      <img
        class="h-40 w-auto pt-8 hidden md:flex"
        src="@/assets/images/dataservice-logo.svg"
        alt="dataservice-logo"
      />
    </div>
    <div v-else class="h-40 w-auto pt-8 hidden md:flex"></div>

    <div
      v-if="routeName == 'SharingSetting'"
      class="md:h-2/5 lg:h-2/3 flex justify-center items-center"
    >
      <!--  v-long-press="500"
       @long-press-start="onLongPressStart"
       @long-press-stop="onLongPressStop" -->
      <img
        v-if="!toggleIncognito"
        class="w-6/12 h-auto hidden md:flex md:w-10/12 lg:w-9/12"
        src="@/assets/images/perpetual-logo.svg"
        alt="perpetual-logo"
      />
      <img
        v-else
        class="w-6/12 h-auto hidden md:flex md:w-10/12 lg:w-9/12"
        src="@/assets/images/perpetual-logo-incognito.svg"
        alt="perpetual-logo-incognito"
      />
    </div>
    <div v-else class="md:h-2/5 lg:h-2/3 flex justify-center items-center">
      <!--  v-long-press="500"
       @long-press-start="onLongPressStart"
       @long-press-stop="onLongPressStop" -->
      <img
        class="w-6/12 h-auto hidden md:flex md:w-10/12 lg:w-9/12"
        src="@/assets/images/perpetual-logo.svg"
        alt="perpetual-logo"
      />
    </div>
    <div
      v-if="routeName == 'SharingSetting'"
      class="flex items-center justify-center"
    >
      <span class="font-proximaMedium text-primary">{{
        $t("section_left_private_mode")
      }}</span>
      <RadioOnoffBorder
        :val="pet"
        :checked="pet.private"
        @inputVal="getInputVal"
      />
    </div>
  </div>
</template>

<script>
import LongPress from "vue-directive-long-press";
import RadioOnoffBorder from "./forms/RadioOnoffBorder.vue";
import { mapActions } from "vuex";

export default {
  props: {
    /*  pet: {
      type: Object,
      default: () => ({ petID: "0", private: false }),
    }, */
  },
  components: {
    RadioOnoffBorder,
  },
  directives: {
    "long-press": LongPress,
  },
  data() {
    return {
      value: 5,
      minusInterval: null,
      toggleIncognito: false,
      radioVal: { title: "privateMode" },
      pet: {},
      publicScreen: false,
    };
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
  },
  created() {
    var retrievedObject = localStorage.getItem("pet");
    this.pet = JSON.parse(retrievedObject);
    this.toggleIncognito = this.pet ? this.pet.private : false;

    const routeName = this.$route.name;

    if (routeName == "PublicProfileNonContact") {
      this.publicScreen = true;
    }
  },
  methods: {
    ...mapActions({
      updatePrivateMode: "petRecord/updatePrivateMode",
    }),
    routeTo(i) {
      if (
        localStorage.getItem("email") &&
        localStorage.getItem("token") &&
        localStorage.getItem("role") != 2
      ) {
        this.$router.push({ path: "/onboardingdashboard" });
      }
    },
    onLongPressStart(e) {
      //console.log("***********///*************");
      //console.log("on-minus-start", e);
      //this.minusInterval = setInterval(() => {
      //  this.value += 1;
      //}, 100);
    },

    /*
    onLongPressStop(e) {
      console.log("on-minus-stop", e);
      console.log(this.value);
      if (this.value >= 50) alert("777777777777");
      clearInterval(this.minusInterval);
    
    },  */
    async getInputVal(val, check) {
      this.toggleIncognito = !this.toggleIncognito;
      console.log("-----", this.pet.petID, val, check);
      const data = {
        check: check,
        pet: this.pet,
      };

      this.updatePrivateMode(data);
    },
    /*  getInputVal() {
      this.toggleIncognito = !this.toggleIncognito;
    }, */
  },
};
</script>

<style scoped>
</style>
